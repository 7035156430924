.onboarding {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px 20px;
    font-family: Arial, sans-serif;
  }
  
  .title {
    font-size: 2.5em;
    color: #333;
    text-align: center;
    margin-bottom: 40px;
  }
  
  .navigation {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
  }
  
  .step {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #e0e0e0;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .step.active {
    background-color: #333;
    color: #fff;
  }
  
  .step.completed {
    background-color: #333;
    color: #fff;
  }
  
  .stepContent {
    background-color: #fff;
    border-radius: 8px;
    padding: 30px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
  }
  
  .stepContent h2 {
    font-size: 1.8em;
    color: #333;
    margin-bottom: 20px;
  }
  
  .stepContent p {
    font-size: 1em;
    color: #666;
    margin-bottom: 20px;
  }
  
  .buttonContainer {
    display: flex;
    justify-content: space-between;
  }
  
  .backButton,
  .nextButton {
    padding: 12px 24px;
    font-size: 1em;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .backButton {
    background-color: #e0e0e0;
    color: #333;
  }
  
  .backButton:hover {
    background-color: #d0d0d0;
  }
  
  .nextButton {
    background-color: #333;
    color: #fff;
  }
  
  .nextButton:hover {
    background-color: #555;
  }
  
  .nextButton:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }