/* src/pages/FriendsPage.module.css */
.friendsPage {
  max-width: 1000px; /* Increased max-width to accommodate BrowseUsers */
  margin: 0 auto;
  padding: 20px;
}

.pageTitle {
  font-size: 2em;
  color: #333;
  margin-bottom: 30px;
}

.sectionTitle {
  font-size: 1.5em;
  color: #444;
  margin-bottom: 15px;
}

.emptyMessage {
  color: #666;
  font-style: italic;
}

.friendRequests, .addFriend, .friendsList, .browseUsersSection {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 30px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.requestList, .friendsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}

.requestItem, .friendItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: inherit;
}

.userLink {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: inherit;
}

.userPhoto, .userPhotoPlaceholder, .friendPhoto, .friendPhotoPlaceholder {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 10px;
  overflow: hidden;
}

.userPhotoPlaceholder, .friendPhotoPlaceholder {
  background-color: #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
  font-size: 2em;
}

.username, .friendName {
  font-weight: bold;
  text-align: center;
}

.acceptButton {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.acceptButton:hover {
  background-color: #45a049;
}

.addFriendForm {
  display: flex;
  gap: 10px;
}

.addFriendInput {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1em;
}

.addFriendButton {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  gap: 5px;
}

.addFriendButton:hover {
  background-color: #45a049;
}

.error {
  color: #dc3545;
  margin-top: 10px;
}

.success {
  color: #28a745;
  margin-top: 10px;
}

.browseUsersSection {
  margin-top: 30px;
}

.loading {
  text-align: center;
  font-size: 1.2em;
  color: #666;
  margin-top: 20px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .requestList, .friendsGrid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
}

@media (max-width: 480px) {
  .requestList, .friendsGrid {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  }

  .addFriendForm {
    flex-direction: column;
  }

  .addFriendButton {
    width: 100%;
    margin-top: 10px;
  }
}