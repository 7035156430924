.createFaivePage {
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
}

.pageTitle {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

@media (max-width: 768px) {
  .createFaivePage {
    padding: 15px;
  }

  .pageTitle {
    font-size: 20px;
  }
}