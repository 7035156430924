.pageContainer {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f2f5;
  padding: 20px;
}

.signInContainer {
  max-width: 400px;
  width: 100%;
  padding: 40px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.title {
  font-size: 24px;
  color: #1c1e21;
  margin-bottom: 20px;
  text-align: center;
}

.signInForm {
  display: flex;
  flex-direction: column;
  gap: 16px; /* Gap between elements */
}

.inputGroup {
  position: relative;
  width: 100%; /* Full width of the container */
}

.inputIcon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #606770;
}

.signInForm input {
  width: 100%; /* Full width of the input group */
  padding: 14px 16px 14px 40px;
  border: 1px solid #dddfe2;
  border-radius: 6px;
  font-size: 17px;
  box-sizing: border-box; /* Ensure padding and border are included in the width */
}

.submitButton {
  background-color: #000;
  color: white;
  border: none;
  padding: 14px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 6px;
  width: 100%; /* Full width of the container */
  box-sizing: border-box; /* Ensure padding and border are included in the width */
  max-width: 100%; /* Ensure button does not overflow */
}

.submitButton:hover {
  background-color: #333;
}

.submitButton:disabled {
  background-color: #e4e6eb;
  color: #bcc0c4;
  cursor: not-allowed;
}

.error, .success {
  margin-top: 12px;
  text-align: center;
  font-size: 14px;
}

.error {
  color: #ff0000;
}

.success {
  color: #42b72a;
}

.resetPassword, .signUpPrompt {
  margin-top: 16px;
  text-align: center;
  font-size: 14px;
}

.resetPasswordButton {
  background: none;
  border: none;
  color: #1877f2;
  cursor: pointer;
  font-size: 14px;
}

.signUpPrompt {
  color: #1c1e21;
}

.link {
  color: #1877f2;
  text-decoration: none;
  font-weight: bold;
}

.link:hover {
  text-decoration: underline;
}