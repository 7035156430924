/* src/App.module.css */
.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main {
  flex-grow: 1;
  padding: 80px 20px 20px;
  background-color: #fff;
}

@media (max-width: 768px) {
  .main {
    padding-top: 60px;
    padding-bottom: 70px; /* Add padding for bottom navigation */
    padding-left: 10px;
    padding-right: 10px;
  }
}

/* You can keep any existing styles for larger screens here */
.nav {
  width: 200px;
  background-color: #f0f0f0;
  padding: 20px;
}

.nav ul {
  list-style-type: none;
  padding: 0;
}

.nav li {
  margin-bottom: 10px;
}

.nav a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
}

/* Add responsive styles for the navigation if needed */
@media (max-width: 768px) {
  .nav {
    width: 100%;
    padding: 10px;
  }

  .nav ul {
    display: flex;
    justify-content: space-around;
  }

  .nav li {
    margin-bottom: 0;
  }
}