.faiveCard {
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
  transition: all 0.3s ease;
  position: relative;
}

.faiveCard:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.username {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: #4a4a4a;
  text-decoration: none;
  transition: color 0.3s ease;
}

.username:hover {
  color: #007bff;
}

.userIcon {
  margin-right: 5px;
  font-size: 12px;
}

.categoryEmoji {
  font-size: 24px;
  opacity: 0.7;
}

.cardContent {
  margin-bottom: 15px;
}

.faiveTitle {
  font-size: 20px;
  font-weight: 700;
  color: #333;
  margin-bottom: 10px;
}

.descriptionContainer {
  margin-bottom: 10px;
}

.faiveDescription {
  font-size: 16px;
  color: #666;
  line-height: 1.4;
  margin-bottom: 5px;
}

.expandButton {
  background: none;
  border: none;
  color: #0066cc;
  cursor: pointer;
  font-size: 14px;
  padding: 0;
  display: flex;
  align-items: center;
  text-decoration: none;
}

.expandButton:hover {
  color: #004499;
}

.expandButton svg {
  margin-right: 5px;
}

.externalLink {
  display: inline-flex;
  align-items: center;
  margin-top: 10px;
  padding: 6px 12px;
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 20px;
  color: #495057;
  text-decoration: none;
  font-size: 14px;
  transition: all 0.3s ease;
  max-width: 100%;
  overflow: hidden;
}

.externalLink:hover {
  background-color: #e9ecef;
  border-color: #dee2e6;
  color: #212529;
}

.externalLink svg {
  flex-shrink: 0;
  margin-right: 8px;
  font-size: 16px;
}

.linkText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cardFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  color: #999;
}

.faiveCategory {
  background-color: #f0f0f0;
  padding: 5px 10px;
  border-radius: 15px;
}

.faiveVisibility {
  font-size: 12px;
  color: #999;
  margin-top: 10px;
}

.faiveActions {
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
}

.faiveActions button {
  background-color: #f0f0f0;
  border: none;
  padding: 5px 10px;
  margin-left: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.faiveActions button:hover {
  background-color: #e0e0e0;
}

.faiveError {
  color: #ff0000;
  font-size: 14px;
  margin-top: 10px;
}