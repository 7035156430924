.browseUsersContainer {
    padding: 20px;
  }
  
  .title {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
    color: #333;
  }
  
  .userGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
  }
  
  .userCard {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 15px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .userCard:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
  
  .userLink {
    text-decoration: none;
    color: inherit;
  }
  
  .userPhoto {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;
  }
  
  .userPhotoPlaceholder {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #e0e0e0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 10px;
    color: #666;
    font-size: 24px;
  }
  
  .username {
    font-size: 18px;
    margin-bottom: 5px;
    color: #333;
  }
  
  .userBio {
    font-size: 14px;
    color: #666;
    margin-bottom: 10px;
    height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  
  .addFriendButton {
    background-color: #333;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    transition: background-color 0.3s ease;
  }
  
  .addFriendButton:hover {
    background-color: #555;
  }
  
  .addFriendButton:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .loadMoreButton {
    display: block;
    margin: 20px auto 0;
    background-color: #333;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .loadMoreButton:hover {
    background-color: #555;
  }
  
  .loadMoreButton:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .error {
    color: #ff0000;
    text-align: center;
    margin-bottom: 20px;
  }