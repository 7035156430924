/* src/pages/HomePage.module.css */
.homePage {
  display: flex;
  flex-direction: column;
}

.mainContent {
  flex: 1;
}

.sidebar {
  display: none;
}

@media (min-width: 1025px) {
  .homePage {
    flex-direction: row;
  }

  .mainContent {
    flex: 1;
    margin-right: 20px;
  }

  .sidebar {
    display: block;
    width: 300px;
  }
}