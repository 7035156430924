.aboutContainer {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .title {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .sectionTitle {
    font-size: 1.8rem;
    color: #444;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
  
  .description {
    font-size: 1.1rem;
    color: #666;
    line-height: 1.6;
    margin-bottom: 1.5rem;
  }
  
  .categoryList {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 1.5rem 0;
  }
  
  .category {
    background-color: #f0f0f0;
    padding: 0.5rem 1rem;
    margin: 0.5rem;
    border-radius: 20px;
    font-size: 1rem;
    transition: all 0.3s ease;
  }
  
  .category:hover {
    background-color: #e0e0e0;
    transform: translateY(-2px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .featureList {
    padding-left: 1.5rem;
    margin-bottom: 1.5rem;
  }
  
  .featureList li {
    font-size: 1.1rem;
    color: #666;
    margin-bottom: 0.5rem;
  }
  
  .ctaContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
  }
  
  .ctaButton {
    background-color: #333;
    color: white;
    padding: 0.8rem 2rem;
    font-size: 1rem;
    text-decoration: none;
    border-radius: 25px;
    transition: all 0.3s ease;
    margin-bottom: 1rem;
  }
  
  .ctaButton:hover {
    background-color: #444;
    transform: translateY(-3px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .backLink {
    color: #666;
    text-decoration: underline;
    font-size: 1rem;
  }
  
  .backLink:hover {
    color: #333;
  }
  
  @media (max-width: 768px) {
    .aboutContainer {
      padding: 1.5rem;
    }
  
    .title {
      font-size: 2rem;
    }
  
    .sectionTitle {
      font-size: 1.5rem;
    }
  
    .description, .featureList li {
      font-size: 1rem;
    }
  
    .category {
      font-size: 0.9rem;
      padding: 0.4rem 0.8rem;
    }
  }