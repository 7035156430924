.feedbackPage {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .title {
    font-size: 2.5em;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .description {
    font-size: 1.1em;
    color: #666;
    margin-bottom: 30px;
    text-align: center;
  }
  
  .additionalInfo {
    margin-top: 40px;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
  }
  
  .additionalInfo h2 {
    color: #333;
    margin-bottom: 15px;
  }
  
  .additionalInfo ul {
    padding-left: 20px;
  }
  
  .additionalInfo li {
    margin-bottom: 10px;
    color: #555;
  }