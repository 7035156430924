.welcomeContainer {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  padding: 2rem;
}

.content {
  max-width: 600px;
  width: 100%;
  text-align: center;
  padding: 2rem;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.logo {
  width: 80px;
  height: auto;
  margin-bottom: 1rem;
}

.title {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.tagline {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 1.5rem;
}

.categoryList {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
}

.category {
  background-color: #f0f0f0;
  padding: 0.5rem 1rem;
  margin: 0.5rem;
  border-radius: 20px;
  font-size: 1rem;
}

.description {
  font-size: 1.1rem;
  color: #444;
  margin-bottom: 2rem;
  line-height: 1.6;
}

.ctaContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.ctaButton {
  width: 200px;
  padding: 0.8rem 2rem;
  font-size: 1rem;
  text-decoration: none;
  border-radius: 25px;
  transition: all 0.3s ease;
  text-align: center;
}

.signupButton {
  background-color: #333;
  color: white;
}

.loginButton {
  background-color: white;
  color: #333;
  border: 2px solid #333;
}

.signupButton:hover, .loginButton:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.learnMoreButton {
  display: inline-block;
  margin-top: 1rem;
  padding: 0.8rem 2rem;
  background-color: #f0f0f0;
  color: #333;
  text-decoration: none;
  border-radius: 25px;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.learnMoreButton:hover {
  background-color: #e0e0e0;
  transform: translateY(-3px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

@media (min-width: 768px) {
  .ctaContainer {
    flex-direction: row;
    justify-content: center;
  }
}