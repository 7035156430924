/* src/components/Navigation.module.css */
.navigation {
  background-color: #ffffff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  padding: 0.5rem 1rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.navContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.logoContainer {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.logo {
  width: 30px;
  height: auto;
  margin-right: 0.5rem;
}

.logoText {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
}

.menuButton {
  display: none;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #333; /* This will make it gray on both mobile and desktop */
}

@media (max-width: 768px) {
  .menuButton {
    display: block;
  }
}

.navLinks {
  display: flex;
  align-items: center;
}

.navLink {
  color: #333;
  text-decoration: none;
  margin-left: 1.5rem;
  font-size: 1rem;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.navLink svg {
  margin-right: 0.5rem;
}

.authLinks {
  display: flex;
  align-items: center;
}

.signInLink {
  color: #333;
  text-decoration: none;
  margin-right: 1rem;
  font-size: 1rem;
}

.signUpButton {
  background-color: #333;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  text-decoration: none;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.signUpButton:hover {
  background-color: #555;
}

.notificationBadge {
  background-color: #3498db;
  color: white;
  border-radius: 50%;
  padding: 0.2rem 0.5rem;
  font-size: 0.8rem;
  margin-left: 0.5rem;
}

@media (max-width: 768px) {
  .menuButton {
    display: block;
  }

  .navLinks {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #ffffff;
    padding: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .navLinks.showMenu {
    display: flex;
  }

  .navLink {
    margin: 0.5rem 0;
  }

  .authLinks {
    display: flex;
    align-items: center;
  }

  .signUpButton {
    margin-left: 1rem;
  }
}