/* src/pages/UserProfilePage.module.css */
.profileContainer {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.profileHeader {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.photoUploadArea {
  position: relative;
  width: 150px;
  height: 150px;
  margin-right: 30px;
}

.profilePhoto, .profilePhotoPlaceholder {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.profilePhotoPlaceholder {
  background-color: #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
  font-size: 4em;
}

.photoUploadOverlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 8px;
  text-align: center;
  border-bottom-left-radius: 75px;
  border-bottom-right-radius: 75px;
}

.photoUploadInput {
  display: none;
}

.photoUploadLabel {
  color: white;
  cursor: pointer;
  font-size: 14px;
}

.profileInfo {
  flex-grow: 1;
}

.profileInfo h2 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 24px;
}

.bio {
  margin-bottom: 15px;
  line-height: 1.5;
}

.bioTextarea {
  width: 100%;
  min-height: 100px;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  resize: vertical;
}

.button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 8px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin-right: 10px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: #45a049;
}

.faivesSection {
  margin-top: 30px;
}

.addFaiveButton {
  background-color: #008CBA;
  color: white;
  border: none;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin-bottom: 20px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.addFaiveButton:hover {
  background-color: #007B9A;
}

.manageFriendsButton {
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 15px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.manageFriendsButton:hover {
  background-color: #45a049;
}

.faivesGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}

.addFaiveForm {
  margin-bottom: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 4px;
}

.friendsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  gap: 10px;
  margin-bottom: 20px;
}

.friendItem {
  text-decoration: none;
  color: inherit;
  text-align: center;
}

.friendPhoto, .friendPhotoPlaceholder {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}

.friendPhotoPlaceholder {
  background-color: #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
  font-size: 1.5em;
}

.friendName {
  display: block;
  font-size: 0.8em;
  margin-top: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (max-width: 600px) {
  .profileHeader {
    flex-direction: column;
    align-items: center;
  }
  .photoUploadArea {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .profileInfo {
    text-align: center;
  }
  .faivesGrid {
    grid-template-columns: 1fr;
  }
}