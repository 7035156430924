/* src/components/FriendActivity.module.css */
.friendActivity {
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 8px;
  margin-bottom: 20px;
}

.title {
  font-size: 1.2em;
  margin-bottom: 15px;
  color: #333;
}

.emptyMessage {
  color: #666;
  font-style: italic;
}

.activityList {
  list-style-type: none;
  padding: 0;
}

.activityItem {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.userLink {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #333;
  margin-right: 10px;
}

.userPhoto, .userPhotoPlaceholder {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  overflow: hidden;
}

.userPhotoPlaceholder {
  background-color: #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
}

.username {
  font-weight: bold;
}

.activityDescription {
  color: #666;
}

.error {
  color: #dc3545;
  font-weight: bold;
}