/* src/components/Feed.module.css */
.feedContainer {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.feedHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.feedTitle {
  font-size: 24px;
  color: #333;
  margin-bottom: 15px;
}

.categoryFilters {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  max-width: 100%;
  overflow-x: auto;
  padding-bottom: 5px;
}

.categoryFilter {
  display: flex;
  align-items: center;
  background-color: #f8f8f8;
  border: 1px solid #e0e0e0;
  padding: 8px 12px;
  border-radius: 20px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
  color: #333;
  white-space: nowrap;
}

.categoryFilter:hover,
.categoryFilter.active {
  background-color: #333;
  color: #fff;
}

.categoryEmoji {
  font-size: 16px;
  margin-right: 5px;
}

.faiveGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
}

@media (max-width: 768px) {
  .feedContainer {
    padding: 0 10px;
  }

  .feedHeader {
    margin-bottom: 15px;
  }

  .feedTitle {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .categoryFilters {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  
  .categoryFilters::-webkit-scrollbar {
    display: none; /* WebKit */
  }
  
  .categoryFilter {
    flex: 0 0 auto;
    margin-right: 10px;
  }

  .categoryFilter:focus {
    outline: none; /* Remove default focus outline */
  }
  
  .categoryFilter:active {
    background-color: #f8f8f8; /* Match the default background color */
    color: #333; /* Match the default text color */
  }
  
  .categoryFilter.active:active {
    background-color: #333; /* Match the active background color */
    color: #fff; /* Match the active text color */
  }
  
  @media (min-width: 768px) {
    .categoryFilters {
      justify-content: center;
      flex-wrap: wrap;
    }
  
    .categoryFilter {
      margin-bottom: 10px;
    }
  }

  .faiveGrid {
    grid-template-columns: 1fr;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .faiveGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}